import React from 'react';

import { InfoGrid } from "components/molecules/infoGrid/InfoGrid.js";
import { Location } from "components/organisms/location/Location.js";

export function LocationGrid(props) { 
  return (
    <InfoGrid {...props}>
      {props.locations.map(location => <Location {...location} />)}
    </InfoGrid>
  )
}

export default LocationGrid;
