import React from 'react';

import Stack from '@mui/material/Stack';

import { ItemRow } from "components/atoms/itemRow/ItemRow";

export function ItemGrid(props) {
    const { rows } = props;
  
    return (
        <Stack spacing={1}>
            {rows.map(row => <ItemRow {...row} />)}
        </Stack>
    );
}

export default ItemGrid;
