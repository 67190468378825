import React from 'react';

import Stack from '@mui/material/Stack';

import { HomeButton } from "components/atoms/homeButton/HomeButton";

export function ButtonGrid(props) {
    const { buttons } = props;
  
    return (
        <Stack spacing={2}>
            {buttons.map(button => <HomeButton {...button} />)}
        </Stack>
    );
}

export default ButtonGrid;
