import React from 'react';

import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import Icon from "components/atoms/icon/Icon.js";

export function Suspect(props) {
  const { name, description, icon, attributes, onSelect } = props;
 
  return (
    <Grid item xs={12} md={6}>
      <Card
        variant="outlined"
        onClick={() => onSelect && onSelect(name)}
        sx={{ background: "#F5F5F5", borderRadius: '10px', borderColor: 'black', border: '3px solid' }}
      >
          <Grid container xs={12} sx={{ pt: 1, pr: 1 }}>
            <Grid item xs={5}>
              <Stack direction="column" spacing={1} align="center" sx={{ pr: 2 }}>
                <Icon name={icon} className="fa-3x" />
                <Typography variant="p" color="text.primary" sx={{ fontWeight: 'bold', fontSize: 13 }}>{name}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Box
                xs={12}
                sx={{
                  height: 1,
                  width: 1,
                }}
              >
                <Typography sx={{ fontSize: 13 }} align="left" color="text.secondary">{description}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Typography sx={{ fontSize: 10, textAlign: 'center' }} color="text.primary">
            <Box
              sx={{
                mt: 2,
                height: 1,
                width: 1,
                fontWeight: 'bold',
              }}
            >
              {(attributes || []).map(attr => attr.value).join(" - ")}
            </Box>
          </Typography>
      </Card>
    </Grid>
  );
}

export default Suspect;
