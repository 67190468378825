import './Layout.css';
import React from 'react';

import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import Icon from "components/atoms/icon/Icon.js";

function Header({ onSelect, selectedLabel, names, onBack }) {
    const defaultStyle = {
        className: "fa-1x",
    };

    return <Paper sx={{ position: 'fixed', top: 0, left: 0, right: 0, mb: 2 }} elevation={4}>
        <Stack direction="row" spacing={2} sx={{ p: 2 }}>
            {onBack && <Icon name="arrow-left" {...defaultStyle} onClick={onBack}/>}
            <Icon name="house" {...defaultStyle} onClick={() => onSelect("Home")}/>
            <Icon name="circle-user" {...defaultStyle} />
        </Stack>
        <Typography variant={"h4"} align="center" color="initial" sx={{ pb: 1, fontWeight: "bold" }}>
            {names[selectedLabel] || selectedLabel}
        </Typography>
    </Paper>;
}

function Footer(props) {
    const { selectedLabel, onSelect, footerButtons } = props;

    const defaultStyle = {
        className: "fa-2x",
    };

    return <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
            showLabels
            value={footerButtons.findIndex(button => button.label === selectedLabel)}
            onChange={(event, newValue) => {
                onSelect(footerButtons[newValue].label);
            }}
        >
            {footerButtons.map(button => <BottomNavigationAction
                label={
                    <Typography variant={"caption"} sx={{fontSize: "9px"}}>
                        {button.label}
                    </Typography>
                }
                icon={<Icon name={button.icon} {...defaultStyle} />}
            />)}
        </BottomNavigation>
    </Paper>
}

export function Layout(props) {
    const { selectedLabel, onSelect, footerButtons, names, onBack } = props;

    return (
        <Container sx={{ m: 0, px: 0, pb: 8, pt: 14}}>
            {props.children}
            <Header onBack={onBack} onSelect={onSelect} selectedLabel={selectedLabel} names={names} />
            <Footer selectedLabel={selectedLabel} onSelect={onSelect} footerButtons={footerButtons} />
        </Container>
    )
}

export default Layout;
