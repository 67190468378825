import React from 'react';

import Grid from '@mui/material/Grid';

export function InfoGrid({ children }) {
  return (
    <Grid container spacing={2} sx={{ px: 2 }}>
      {children}
    </Grid>
  );
}

export default InfoGrid;
