import React from 'react';

import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const Button = ({ value, label }) => {
    return <ToggleButton sx={{ px: 2, py: 1 }} value={value} aria-label={`${value} aligned`}>
        <Typography variant="h2" color="text.primary" sx={{ fontWeight: 'bold', fontSize: 14, textAlign: "center" }}>
            {label}
        </Typography>
    </ToggleButton>;
}

export function ToggleMenu(props) { 
    const { selected, setSelected, selections } = props;

    const handleAlignment = (
        event,
        newAlignment,
    ) => {
        newAlignment && setSelected(newAlignment);
    };

    return <ToggleButtonGroup
        fullWidth={true}
        value={selected}
        exclusive
        onChange={handleAlignment}
        aria-label="text alignment"
    >
        {selections.map(selection => <Button {...selection} />)}
    </ToggleButtonGroup>
}

export default ToggleMenu;
