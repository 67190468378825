// import './Info.css';
import React from 'react';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';

import Icon from "components/atoms/icon/Icon.js";

function format_time(s) {
  const dtFormat = new Intl.DateTimeFormat('en-GB', {
    // timeZone: 'UTC',
    dateStyle: 'full',
    timeStyle: 'long'
  });
  
  return dtFormat.format(new Date(s * 1e3));
}

export function ItemRow({ icon, label, timestamp }) {
  return (
    <Card sx={{ p: 1 }}>
      <Stack direction="row" alignItems={"center"} justifyContent={"space-between"} spacing={2}>
        <Stack>
          <Typography variant="p" color="text.primary" sx={{ fontWeight: 'bold', fontSize: 14 }}>{label}</Typography>
          <Typography variant="p" color="text.primary" sx={{ fontSize: 12 }}>{format_time(timestamp)}</Typography>
        </Stack>
        <Icon name={icon} />
      </Stack>
    </Card>
  );
}

export default ItemRow;
