import React, { useMemo } from 'react';

// import { Stub } from './stub.js';

import { ItemGrid } from 'components/molecules/itemGrid/ItemGrid.js';

export function Timeline({ events }) {
  const revents = useMemo(() => {
    return events.map(event => {
      return {
        label: event.details.label,
        icon: event.details.icon,
        timestamp: Date.parse(event.timestamp).valueOf() / 1000
      };
    });
  }, [events]);

  return (
    <ItemGrid rows={revents} />
  )
}

export default Timeline;
