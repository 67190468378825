import React, { useState } from 'react';

import { apiService } from "services/services";

import { Murdle as MurdlePage } from "components/pages/murdle/Murdle";

export function Murdle(props) {
  const [murdleData, setMurdleData] = useState(null);
  const {
    selectedLabel,
    onSelect, 
    subSection,
    setSubSection
  } = props;

  React.useEffect(() => {
    apiService.get(`users/1/quests`).then((resp) => {
      const data = resp.data;

      const mappedData = {
        suspects: [],
        weapons: [],
        locations: [],
        cases: []
      };

      data.forEach(res => {
        const quest = res.quest;

        mappedData.cases.push(
          {
            name: quest.name,
            description: quest.description,
            status: "AVAILABLE",
            difficulty: "EASY",
            estimatedCompletionHours: 2,
            clueCount: res.clues.length,
            detectiveCount: quest.total_users_count
          }
        );

        res.clues.forEach(clue => {
          const clueM = { ...clue.details, events: clue.events };
          if (clue.clue_type === "suspect") {
            mappedData.suspects.push(clueM);
          } else if (clue.clue_type === "weapon") {
            mappedData.weapons.push(clueM);
          } else if (clue.clue_type === "location") {
            mappedData.locations.push(clueM);
          }
        });
      });

      setMurdleData(mappedData);
    });
  }, []);

  return (
    <MurdlePage {...{
      murdleData,
      setMurdleData,
      selectedLabel,
      onSelect,
      subSection,
      setSubSection
    }} />
  )
}

export default Murdle;
