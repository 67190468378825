import React, { useMemo } from 'react';
import Stack from '@mui/material/Stack';

import { ButtonGrid } from "components/molecules/buttonGrid/ButtonGrid";
import { WeaponGrid } from "components/organisms/weaponGrid/WeaponGrid";
import { LocationGrid } from "components/organisms/locationGrid/LocationGrid";
import { CaseDetails } from "components/organisms/caseDetails/CaseDetails";
import { CasesView } from "components/organisms/casesView/CasesView";
import Timeline from 'components/organisms/timeline/Timeline';

import { Suspects } from "components/pages/suspects/Suspects";

export function Murdle(props) {
  const {
    murdleData,
    selectedLabel,
    onSelect,
    subSection,
    setSubSection
  } = props;

  const section = useMemo(() => selectedLabel || "Suspects", [selectedLabel]);

  if (!murdleData) return null;

  return (
    <Stack direction="column">
      <>
        {section === "Home" && <ButtonGrid page={"home"} buttons={[
          { icon: "handcuffs", label: "VIEW ALL CASES", onClick: () => onSelect("Cases") },
          { icon: "compass", label: "EXPLORE THE AREA" },
          { icon: "magnifying-glass", label: "EXAMINE A CLUE", onClick: () => onSelect("Examine") },
          { icon: "user-secret", label: "VIEW DETECTIVE KIT" },
          { icon: "trophy", label: "VIEW CASE MEMENTOS" },
          { icon: "bars-progress", label: "VIEW PROGRESS" },
          { icon: "user-pen", label: "CHANGE APPEARANCE" },
          { icon: "gear", label: "VIEW SETTINGS" },
        ]} />}
        {section === "Suspects" && <Suspects {...props} suspects={murdleData.suspects} />}
        {section === "Weapons" && <WeaponGrid weapons={murdleData.weapons} />}
        {section === "Locations" && <LocationGrid locations={murdleData.locations} />}
        {section === "Examine" && <ButtonGrid buttons={[
          { icon: "keyboard", label: "ENTER CODE" },
          { icon: "qrcode", label: "SCAN QR CODE" },
          { icon: "wifi-nfc", label: "SCAN TOKEN" },
          { icon: "binoculars", label: "DETECT NEARBY CLUES" },
        ]} />}
        {section === "Cases" && <CasesView cases={murdleData.cases} />}
      </>
    </Stack>
  )
}

export default Murdle;
