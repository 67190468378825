import './App.css';
import React, { useState, useCallback } from 'react';

import Layout from 'components/molecules/layout/Layout';
import Murdle from 'views/Murdle';

const footerButtons = [
  { icon: "users", label: "Suspects" },
  { icon: "crosshairs", label: "Weapons" },
  { icon: "globe", label: "Locations" },
  { icon: "brain", label: "Motives" },
  { icon: "grip", label: "Deductions" },
];

const names = {
  "Home": "Murdo",
  "CaseDetails": "Case Details"
};

function App() {
  const [selectedLabel, setSelectedLabel] = React.useState("Cases");
  const [subSection, setSubSection] = React.useState(null);

  const onSelect = useCallback((newLabel) => {
    setSelectedLabel(newLabel);
    setSubSection(null);
  }, [setSelectedLabel]);

  return (
    <div className="App">
      <Layout
        onBack={subSection && (() => setSubSection(null))}
        selectedLabel={selectedLabel}
        onSelect={onSelect}
        names={names}
        footerButtons={footerButtons}
      >
        <Murdle
          selectedLabel={selectedLabel}
          onSelect={onSelect}
          subSection={subSection}
          setSubSection={setSubSection}
        />
      </Layout>    
    </div>
  );
}

export default App;
