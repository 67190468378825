export class ApiService {
  constructor(baseUrl, httpAdapter) {
    this._baseUrl = baseUrl;
    this._httpAdapter = httpAdapter;

    this._userSession = undefined;
  }

  register_unauthorized(callback) {
    this._callback = callback;
  }

  // General

  async get(url, headers={}) {
    return this._httpAdapter.get(this._buildUrl(url), headers);
  }

  async patch(url, body={}, headers={}) {
    return this._httpAdapter.patch(
      this._buildUrl(url),
      body,
      { "Content-Type": "application/json; charset=UTF-8", ...headers }
    );
  }

  async post(url, body={}, headers={}) {
    return this._httpAdapter.post(
      this._buildUrl(url),
      body,
      { "Content-Type": "application/json; charset=UTF-8", ...headers }
    );
  }

  async put(url, body={}, headers={}) {
    return this._httpAdapter.put(
      this._buildUrl(url),
      body,
      { "Content-Type": "application/json; charset=UTF-8", ...headers }
    );
  }

  async delete(url, headers={}) {
    return this._httpAdapter.delete(this._buildUrl(url), headers);
  }

  // Authed Requests

  async aget(url, headers={}) {
    const result = await this.get(url, this._buildAuthHeaders(headers));
    if (result.status === 401) {
      this._handleUnauthorized();
    }
    return result;
  }

  async apatch(url, body={}, headers={}) {
    return this.patch(url, body, this._buildAuthHeaders(headers));
  }

  async apost(url, body={}, headers={}) {
    const result = await this.post(url, body, this._buildAuthHeaders(headers));
    if (result.status === 401) {
      this._handleUnauthorized();
    }
    return result;
  }

  async aput(url, body={}, headers={}) {
    return this.put(url, body, this._buildAuthHeaders(headers));
  }

  async adelete(url, headers={}) {
    return this.delete(url, this._buildAuthHeaders(headers));
  }

  // Other

  async logout() {
    await this.apost('logout', {});
    this._userSession = undefined;
  }

  setUserSession(userSession) {
    this._userSession = userSession;
  }

  // Private

  _handleUnauthorized() {
    if (this._callback) {
      this._callback();
    }
  }

  _guardUserSession() {
    if (!this._userSession) {
      throw Error('User Session Missing');
    }
  }

  _buildAuthHeaders(headers={}) {
    this._guardUserSession();
    return {
      "X-Auth-Token": this._userSession.authToken,
      "X-Auth-User": this._userSession.userId,
      ...headers
    }
  }

  _buildUrl(url) {
    return `${this._baseUrl}/${url}`;
  }
}

export default ApiService;
