export class HttpAdapter {
  async get(url, headers={}) {
    const response = await fetch(url, { method: 'GET', headers: headers });
    return this._processResponse(response);
  }

  async patch(url, body={}, headers={}) {
    const response = await fetch(url, {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: headers
    });
    return this._processResponse(response);
  }

  async post(url, body={}, headers={}) {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: headers
    });
    return this._processResponse(response);
  }

  async put(url, body={}, headers={}) {
    const response = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: headers
    });
    return this._processResponse(response);
  }

  async delete(url, headers={}) {
    const response = await fetch(url, { method: 'DELETE', headers: headers });
    return this._processResponse(response);
  }

  async _processResponse(response) {
    if (!response.ok) {
      return { success: false, status: response.status, data: undefined };
    }
    const data = await response.json();
    return { success: true, status: response.status, data: data };
  }
}

export default HttpAdapter;
