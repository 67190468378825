// import './Info.css';
import React from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import Icon from "components/atoms/icon/Icon.js";

export function HomeButton({ icon, label, onClick }) {
  return (
    <Button size="large"
      sx={{
          borderRadius: '10px', borderColor: 'black', border: '3px solid',
          color: "black",
          display: "block",
          backgroundColor: "#D8D8D8",
          px: 2
      }}
      onClick={onClick}
    >
        <Stack direction="row" justifyContent="left" alignItems="center" spacing={2}>
            <Icon name={icon} className="fa-3x" />
            <Typography variant="p" color="text.primary" sx={{ fontWeight: 'bold', fontSize: 14 }}>{label}</Typography>
        </Stack>
    </Button>
  );
}

export default HomeButton;
