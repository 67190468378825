import React from 'react';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

import Icon from "components/atoms/icon/Icon.js";

// Title

export function CasesView(props) {
    const { cases } = props;

    // TODO: select case
    const scase = cases[0];

    const { name, description, status, difficulty, estimatedCompletionHours, clueCount, detectiveCount } = scase;

    const stats = [
        { icon: "users", value: detectiveCount },
        { icon: "magnifying-glass", value: clueCount },
        { icon: "gauge", value: difficulty },
        { icon: "clock", value: `${estimatedCompletionHours}H` }
    ];

    const statusIcon = {
        "LOCKED": "lock",
        "AVAILABLE": "circle-play"
    }[status] || "lock";

    return (
        <Box sx={{ px: 2 }}>
            <Card variant="outlined" sx={{ background: "#D8D8D8", borderRadius: '10px', borderColor: 'black', border: '3px solid', px: 2, py: 2 }}>
                <Stack spacing={2}>
                    <Typography variant="h2" color="text.primary" sx={{ fontWeight: 'bold', fontSize: 14, textAlign: "center" }}>{name}</Typography>
                    <Grid container spacing={2} sx={{ px: 4 }}>
                        {stats.map(stat => <Grid item xs={6} md={2}>
                            <Stack direction="row" spacing={2}>
                                <Icon name={stat.icon} className="fa-2x" />
                                <Typography variant="h4" color="text.primary" sx={{ fontWeight: 'bold', fontSize: 14, textAlign: "center" }}>{stat.value}</Typography>
                            </Stack>
                        </Grid>)}
                    </Grid>
                    <Typography variant="p" color="text.primary" sx={{ fontSize: 10, textAlign: "center" }}>{description}</Typography>
                    <Icon name={statusIcon} className="fa-2x" />
                </Stack>
            </Card>
        </Box>
    );
}

export default CasesView;
