import React from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { ToggleMenu } from "components/atoms/toggleMenu/ToggleMenu";
import { ButtonGrid } from "components/molecules/buttonGrid/ButtonGrid";

// Title

export function CaseDetails(props) {
    const [alignment, setAlignment] = React.useState('Information');
    const { caseName } = props;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h2" color="text.primary" sx={{ fontWeight: 'bold', fontSize: 14, textAlign: "center" }}>{caseName}</Typography>
            </Grid>
            <Grid item xs={12}>
                <ToggleMenu
                    selected={alignment}
                    setSelected={setAlignment}
                    selections={
                        [
                            { value: "Information", label: "Information" },
                            { value: "Actions", label: "Actions" },
                        ]
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <ButtonGrid
                    buttons={[
                        { icon: "hand-point-right", label: "ACCUSE SUSPECT" },
                        { icon: "file", label: "INTERVIEW SUSPECT" },
                        { icon: "magnifying-glass", label: "EXAMINE A CLUE" },
                    ]}
                />
            </Grid>
        </Grid>
    );
}

export default CaseDetails;
