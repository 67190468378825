import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'

import 'index.css'
import App from 'App'
import store from 'store'

// As of React 18
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
