import React from 'react';

import { SuspectGrid } from "components/organisms/suspectGrid/SuspectGrid";

import { Suspect } from "components/pages/suspect/Suspect";

export function Suspects(props) {
  const {
    suspects,
    subSection,
    setSubSection
  } = props;

  return (
    subSection ?
    <Suspect suspect={suspects.find(suspect => suspect.name === subSection)} /> :
    <SuspectGrid suspects={suspects} onSelect={(name) => setSubSection(name)} />
  );
}

export default Suspects;
