import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    fa4,
    fa5,
    faArchway,
    faArrowLeft,
    faBarsProgress,
    faBed,
    faBinoculars,
    faBrain,
    faChessKnight,
    faCirclePlay,
    faCircleQuestion,
    faCircleUser,
    faClock,
    faCompass,
    faCrosshairs,
    faFile,
    faGauge,
    faGear,
    faGlobe,
    faGraduationCap,
    faGrip,
    faHandcuffs,
    faHandPointRight,
    faHatCowboy,
    faHatWizard,
    faHouse,
    faKeyboard,
    faLock,
    faMagnifyingGlass,
    faMugHot,
    faPalette,
    faQrcode,
    faSkull,
    faSuitcase,
    faTrophy,
    faUsers,
    faUserPen,
    faUserSecret,
    faWifi,
    faWineGlass,
} from '@fortawesome/free-solid-svg-icons'

const icons = {
    "4": {
        faIcon: fa4,
    },
    "5": {
        faIcon: fa5,
    },
    "archway": {
        faIcon: faArchway,
    },
    "arrow-left": {
        faIcon: faArrowLeft,
    },
    "bars-progress": {
        faIcon: faBarsProgress,
    },
    "bed": {
        faIcon: faBed,
    },
    "binoculars": {
        faIcon: faBinoculars,
    },
    "brain": {
        faIcon: faBrain,
    },
    "chess-knight": {
        faIcon: faChessKnight,
    },
    "circle-play": {
        faIcon: faCirclePlay,
    },
    "circle-question": {
        faIcon: faCircleQuestion,
    },
    "circle-user": {
        faIcon: faCircleUser,
    },
    "clock": {
        faIcon: faClock,
    },
    "compass": {
        faIcon: faCompass,
    },
    "crosshairs": {
        faIcon: faCrosshairs,
    },
    "file": {
        faIcon: faFile,
    },
    "gear": {
        faIcon: faGear,
    },
    "globe": {
        faIcon: faGlobe,
    },
    "graduation-cap": {
        faIcon: faGraduationCap,
    },
    "grip": {
        faIcon: faGrip,
    },
    "gauge": {
        faIcon: faGauge,
    },
    "hand-point-right": {
        faIcon: faHandPointRight,
    },
    "handcuffs": {
        faIcon: faHandcuffs,
    },
    "hat-cowboy": {
        faIcon: faHatCowboy,
    },
    "hat-wizard": {
        faIcon: faHatWizard,
    },
    "house": {
        faIcon: faHouse,
    },
    "keyboard": {
        faIcon: faKeyboard,
    },
    "lock": {
        faIcon: faLock,
    },
    "magnifying-glass": {
        faIcon: faMagnifyingGlass,
    },
    "mug-hot": {
        faIcon: faMugHot,
    },
    "palette": {
        faIcon: faPalette,
    },
    "qrcode": {
        faIcon: faQrcode,
    },
    "skull": {
        faIcon: faSkull,
    },
    "suitcase": {
        faIcon: faSuitcase,
    },
    "trophy": {
        faIcon: faTrophy,
    },
    "user-pen": {
        faIcon: faUserPen,
    },
    "user-secret": {
        faIcon: faUserSecret,
    },
    "users": {
        faIcon: faUsers,
    },
    "wifi": {
        faIcon: faWifi,
    },
    "wifi-nfc": {
        faIcon: faWifi,
        settings: {
            transform: {
                "rotate": 90,
            },
        }
    },
    "wine-glass": {
        faIcon: faWineGlass,
    },
};

export function Icon(props) {
    const icon = icons[props.name];

    if (!icon) return null;

    const newProps = {
        ...(icon.settings || {}),
        ...props,
        transform: {
            ...(icon.settings?.transform || {}),
            ...(props?.transform || {}),
        },
    };

    return <FontAwesomeIcon icon={icon.faIcon} {...newProps} />;
};

export default Icon;
