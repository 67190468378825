// import './Info.css';
import React from 'react';

import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import Icon from "components/atoms/icon/Icon.js";

export function Info(props) {
  const { name, description, icon, attributes } = props;

  return (
    <Grid item xs={6} md={3}>
      <Card variant="outlined" sx={{ background: "#D8D8D8", borderRadius: '10px', borderColor: 'black', border: '3px solid', px: 2, py: 2 }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Icon name={icon} className="fa-3x" />
          <Typography variant="p" color="text.primary" sx={{ fontWeight: 'bold', fontSize: 14, textAlign: 'center' }}>{name}</Typography>
          <Typography sx={{ fontSize: 12, textAlign: 'center' }} color="text.secondary">
            <Box
              sx={{
                height: 1,
                width: 1,
                fontWeight: 'bold',
              }}
            >
              {(attributes || []).map(attr => attr.value).join(" - ")}
            </Box>
          </Typography>
          <Typography sx={{ fontSize: 13 }} color="text.secondary">{description}</Typography>
        </Stack>
      </Card>
    </Grid>
  );
}

export default Info;
