import React from 'react';

import { InfoGrid } from "components/molecules/infoGrid/InfoGrid.js";
import { Suspect } from "components/organisms/suspect/Suspect.js";

export function SuspectGrid(props) { 
  return (
    <InfoGrid {...props}>
      {props.suspects.map(suspect => <Suspect {...suspect} onSelect={props.onSelect} />)}
    </InfoGrid>
  )
}

export default SuspectGrid;
