import React from 'react';

import { InfoGrid } from "components/molecules/infoGrid/InfoGrid.js";
import { Weapon } from "components/organisms/weapon/Weapon.js";

export function WeaponGrid(props) { 
  return (
    <InfoGrid {...props}>
      {props.weapons.map(weapon => <Weapon {...weapon} />)}
    </InfoGrid>
  )
}

export default WeaponGrid;
