import React from 'react';

import { Info } from "components/atoms/info/Info.js";

export function Weapon(props) {
  return (
    <Info {...props} />
  )
}

export default Weapon;
